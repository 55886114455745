@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .text-input {
        @apply w-full mt-1 px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-primary focus:border-primary
    }
    .label-form{
        @apply block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300
    }
    .input-form{
        @apply bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white
    }
    .btn-submit {
        @apply w-full flex justify-center py-2 px-4 border border-transparent font-medium rounded-md text-white bg-primary hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary
    }
    .btn-primary{
        @apply text-white bg-primary hover:bg-green-400 focus:ring-4 focus:ring-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary dark:hover:bg-green-400 dark:focus:ring-primary
    }
    .btn-secondary{
        @apply text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600
    }
    .btn-danger{
        @apply text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5
    }
    .modal-form{
        @apply fixed w-full h-full top-0 left-0 flex items-center justify-center z-40
    }
    .modal-form-outside{
        @apply absolute w-full h-full bg-gray-900 opacity-50
    }
}