body{
  background-color: #8FBE0A;
  /* background-color: #252D39; */
}

/* width */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px gray; 
    border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #2EC862;;
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #252D39;
    cursor: pointer;
}

.red-button {
    background-color: red;
    color: white; 
    margin: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    transition: 'background-color 0.3s';
    gap: 0px; /* Atur jarak antara tombol */
    /* transition: 'background-color 0.3s', // Add a smooth transition effect
    
    ':hover': {
      backgroundColor: 'grey',
    }, */
  }
  
.red-button:hover {
    background-color: grey;
  }

  .SelectedRole {
      background-color: white;
      color: black;
    }

    .half-width {
        width: 50%;
      }
    .btn-margin {
      margin: 20px; /* Adjust the margin value as needed */
    }

    .file-upload-button {
      display: inline-block;
      padding: 10px 20px;
      background-color: #252D39;
      color: #fff;
      border-radius: 5px;
      cursor: pointer;
    }
    
    .file-upload-button:hover {
      background-color: GREY;
    }